import styles from '../StreamPage.module.scss';
import RenderHTML from '../../../components/RenderHTML/RenderHTML';
import { StreamShape } from '../../../apis/models/stream';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

export function Schedule({
  stream,
  isStreamLoading
}) {
  return (
    <div className={styles.infoSection}>
      {isStreamLoading || !stream ? <Skeleton className={styles.streamDescriptionLoader} /> :
        <RenderHTML htmlString={stream?.description} />}
    </div>
  );
}

Schedule.propTypes = {
  stream: StreamShape.isRequired,
  isStreamLoading: PropTypes.bool.isRequired
};

Schedule.defaultProps = {};
