import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './StreamPage.module.scss';
import { TrainersSection } from './components/TrainersSection';
import { MainSection } from './components/MainSection';
import useStreams from '../../hook/useStreams';

function StreamPage() {
  const { id } = useParams();
  const {
    stream,
    loadStream,
    isStreamLoading
  } = useStreams();

  useEffect(() => {
    loadStream(id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hasPeoples = stream?.speakers?.length > 0 || stream?.managers?.length > 0;

  return (
    <div className={hasPeoples ? styles.holder : styles.fullWidthHolder}>
      <MainSection stream={stream} isStreamLoading={isStreamLoading} />
      <TrainersSection stream={stream} isStreamLoading={isStreamLoading} />
    </div>
  );
}

export default StreamPage;
