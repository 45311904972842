import { APIError } from './configs/axiosUtils';
import api from './configs/axiosConfig';
import coursesAPI from './coursesAPI';

const baseUrl = '/v2/user/streams';

export const streamsAPI = {
  fetchStreams: async (pagination) => {
    try {
      const streams = (
        await api.get(`${baseUrl}`, {
          params: pagination,
        })
      ).data;

      const coverMap = new Map();

      for (const stream of streams.items) {
        try {
          const cover = await coursesAPI.getImageByID(stream.id);
          coverMap.set(stream.id, cover);
        } catch (e) {
          console.error(
            `Stream with id: ${stream.id} has no cover, error: `,
            e,
          );
        }
      }

      return {
        pagination: streams.pagination,
        items: streams.items.map((s) => ({
          ...s,
          cover: coverMap.get(s.id),
        })),
      };
    } catch (e) {
      new APIError(e);
    }
  },
  fetchStream: async (id) => {
    try {
      const stream = (
        await api.get(`${baseUrl}/${id}`)
      ).data;

      let cover;

      try {
        cover = await coursesAPI.getImageByID(stream.id);
      } catch (e) {
        console.error(`Stream with id: ${stream.id} has no cover, error: `, e);
      }

      const managersCoverMap = new Map();
      const speakersCoverMap = new Map();

      for (const manager of stream.managers) {
        const managerCover = await coursesAPI.getImageByID(manager.id);
        managersCoverMap.set(manager.id, managerCover);
      }

      for (const speaker of stream.speakers) {
        const managerCover = await coursesAPI.getImageByID(speaker.id);
        speakersCoverMap.set(speaker.id, managerCover);
      }

      return {
        ...stream,
        managers: stream.managers.map((m) => ({
          ...m,
          cover: managersCoverMap.get(m.id),
        })),
        speakers: stream.speakers.map((s) => ({
          ...s,
          cover: speakersCoverMap.get(s.id),
        })),
        cover,
      };
    } catch (e) {
      new APIError(e);
    }
  },
  fetchStreamSpecs: async () => {
    try {
      return (
        await api.get(`${baseUrl}/filters`)
      ).data;
    } catch (e) {
      new APIError(e);
    }
  },
};
