/* eslint-disable arrow-body-style */
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useLocation, useNavigate } from 'react-router-dom';
import { coursesAPI } from '../apis/coursesAPI';
import useAuth from '../hook/useAuth';
import useError from '../hook/useError';
import { CoursesSortEnum, uniqueMerge } from '../utils/helper';
import {
  COURSES_HOME_ELEMENTS_COUNT,
  COURSES_MYCOURSES_ELEMENTS_COUNT,
  COURSES_NEW_ELEMENTS_COUNT,
} from '../utils/settings';

export const CoursesContext = createContext(null);

export function CoursesProvider({ children }) {
  const { user, setUserNull } = useAuth();
  const { error } = useError();
  const { pathname } = useLocation();

  const [course, setCourse] = useState(null);

  const [courses, setCourses] = useState([]);
  const [coursesPagination, setCoursesPagination] = useState({});

  const [newCourses, setNewCourses] = useState([]);

  const [userCourses, setUserCourses] = useState([]);
  const [userCoursesPagination, setUserCoursesPagination] = useState({});

  const [freeCourses, setFreeCourses] = useState([]);
  const [freeCoursesPagination, setFreeCoursesPagination] = useState({});

  const [video, setVideo] = useState(null);
  const [videos, setVideos] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  const [isSingleVideoLoading, setIsSingleVideoLoading] = useState(true);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [isLoadingNewCourses, setIsLoadingNewCourses] = useState(true);
  const [isLoadingUserCourses, setIsLoadingUserCourses] = useState(true);
  const [isLoadingFreeCourses, setIsLoadingFreeCourses] = useState(true);

  const [searchInputText, setSearchInputText] = useState('');
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchResultsLength, setSearchResultsLength] = useState(0);

  const navigate = useNavigate();

  const resetState = useCallback(() => {
    setCourses([]);
    setUserCourses([]);
    setFreeCourses([]);

    setSearchInputText('');
    setSearchResultsLength(0);
    setCoursesPagination({});
    setFreeCoursesPagination({});
    setUserCoursesPagination({});

    // setVideos([]);
    // setCourse(null);
  }, [pathname]);

  // loading state for search input. It's logic differs from regular
  // loading state, so it has a separate hook
  useEffect(() => {
    if (!isLoadingFreeCourses || !isLoadingUserCourses || !isLoadingCourses) {
      setSearchInProgress(false);
    }
  }, [isLoadingFreeCourses, isLoadingUserCourses, isLoadingCourses]);

  // Reset loaded entities in case of error
  useEffect(() => {
    setCourse(null);
    setVideo(null);
  }, [error]);

  // Load new courses on user change/init
  useEffect(() => {
    if (user) {
      setUserCourses([]);
      // eslint-disable-next-line no-use-before-define
      loadNewCourses();
    }
  }, [user]);

  /**
   * Request image data for the object and extend it
   * @param {Object} rawObj Object with id property
   * @returns Extended object
   */
  /**
   * Request image data for the object and extend it
   * @param {Object} rawObj Object with id property
   * @returns Extended object
   */
  // eslint-disable-next-line no-underscore-dangle
  const _extendWithImage = async (rawObj) => {
    try {
      const respImg = await coursesAPI.getImageByID(rawObj.id);
      return { ...rawObj, image: respImg };
    } catch (_) {
      return rawObj;
    }
  };

  /**
   * Extend courses with images data
   * Wait for all promises for image data to be executed
   * Then extend courses and return them
   * @param {Array} rawCourses Array of courses objects
   * @returns Extended courses objects
   */
  // eslint-disable-next-line no-underscore-dangle
  const _extendAllWithImages = async (rawCourses) => {
    const imgPromises = rawCourses?.map((c) => _extendWithImage(c));
    const extCourses = await Promise.all(imgPromises);
    return extCourses;
  };

  /**
   * Load all courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadCourses = async (page, search, size, sort) => {
    setIsLoadingCourses(true);
    try {
      const resp = await coursesAPI.getCourses(undefined, undefined, page, search, size, sort);
      setSearchResultsLength(resp?.pagination.totalCount);
      const extCourses = await _extendAllWithImages(resp?.items);
      const mergedCourses = uniqueMerge(courses, extCourses, 'id');
      setCourses(mergedCourses);
      setCoursesPagination(resp ? { ...resp?.pagination } : {});
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingCourses(false);
    }
  };

  /**
   * Load new courses
   */
  const loadNewCourses = async () => {
    setIsLoadingNewCourses(true);
    try {
      const resp = await coursesAPI.getCourses(
        undefined,
        undefined,
        undefined,
        undefined,
        COURSES_NEW_ELEMENTS_COUNT,
        CoursesSortEnum.createdAtDesc,
      );
      const extCourses = await _extendAllWithImages(resp?.items);
      setNewCourses([...extCourses]);
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingNewCourses(false);
    }
  };

  /**
   * Load user's courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadUserCourses = async (page, search, size, sort) => {
    setIsLoadingUserCourses(true);
    try {
      const resp = await coursesAPI.getCourses(undefined, true, page, search, size, sort);
      if (resp) {
        setSearchResultsLength(resp.pagination.totalCount);
        const extCourses = await _extendAllWithImages(resp.items);
        const mergedUserCourses = uniqueMerge(userCourses, extCourses, 'id');
        setUserCourses(mergedUserCourses);
        setUserCoursesPagination(resp ? { ...resp.pagination } : {});
      } else {
        setUserCourses([]);
        setUserCoursesPagination({});
      }
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingUserCourses(false);
    }
  };

  /**
   * Load free courses
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sort Sort list by field name with + or - at the end for select sort direction
   */
  const loadFreeCourses = async (page, search, size, sort) => {
    setIsLoadingFreeCourses(true);
    try {
      const resp = await coursesAPI.getCourses(true, undefined, page, search, size, sort);
      if (resp) {
        setSearchResultsLength(resp.pagination.totalCount);
        const extCourses = await _extendAllWithImages(resp.items);
        const mergedFreeCourses = uniqueMerge(freeCourses, extCourses, 'id');
        setFreeCourses(mergedFreeCourses);
        setFreeCoursesPagination(resp ? { ...resp.pagination } : {});
      } else {
        setFreeCourses([]);
        setFreeCoursesPagination({});
      }
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingFreeCourses(false);
    }
  };

  /**
   * Loads course and related videos
   * @param {String} id Course ID
   */
  const loadCourseById = async (id) => {
    setCourse(null);
    setVideos([]);
    setIsLoadingVideos(true);

    try {
      const resp = await coursesAPI.getCourseById(id);
      if (resp && resp?.status === 200) {
        const extCourse = await _extendWithImage(resp.data);
        setCourse(extCourse);
      } else {
        setCourse(undefined);
      }

      const videosResp = await coursesAPI.getCourseVideos(id);
      if (videosResp && videosResp?.status === 200) {
        if (videosResp?.data?.items?.length) {
          const extVideos = await _extendAllWithImages(videosResp.data.items);
          setVideos(extVideos);
        }
      }
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingVideos(false);
    }
  };

  const loadMoreVideos = async (page) => {
    try {
      const resp = await coursesAPI.getCourseVideos(course.id, page);
      if (resp && resp?.status === 200) {
        if (resp?.data?.items?.length) {
          const extVideos = await _extendAllWithImages(resp.data.items);
          setVideos((prev) => [...prev, ...extVideos]);
        }
      }
    } catch (_) {
      setUserNull();
    } finally {
      setIsLoadingVideos(false);
    }
  };

  /**
   * Loads the video by ID and parent course ID
   * @param {String} videoId Video ID
   * @param {String} courseId Course ID
   */
  const loadVideoById = async (videoId, courseId) => {
    setIsSingleVideoLoading(true);
    setVideo(null);
    try {
      const resp = await coursesAPI.getVideoById(videoId, courseId);
      if (resp && resp?.status === 200) {
        const extVideo = await _extendWithImage(resp.data);
        setVideo(extVideo);
      } else {
        setVideo(undefined);
      }
    } catch (_) {
      setUserNull();
    } finally {
      setIsSingleVideoLoading(false);
    }
  };

  const subscribeToCourse = async (courseId) => {
    try {
      const resp = await coursesAPI.subscribeToCourse(courseId);
      if (resp) {
        if (course.id === courseId) {
          setCourse({ ...course, isPurchased: true });
        }
      }
      navigate(`/mycourses/${courseId}/${videos[0]?.id}`);
    } catch (_) {
      setUserNull();
    }
  };

  const loadCurrentPageCourses = (currentPage, searchText) => {
    setCourses([]);
    setFreeCourses([]);
    setUserCourses([]);
    setSearchInputText(searchText.trim());

    if (currentPage === 'mycourses') {
      loadUserCourses(
        1,
        searchText.trim(),
        COURSES_MYCOURSES_ELEMENTS_COUNT,
        CoursesSortEnum.purchasedAtDesc,
      );
      return;
    }

    if (currentPage === 'free') {
      loadFreeCourses(
        1,
        searchText.trim(),
        COURSES_HOME_ELEMENTS_COUNT,
        CoursesSortEnum.usersCountDesc,
      );
      return;
    }
    loadCourses(
      1,
      searchText.trim(),
      COURSES_HOME_ELEMENTS_COUNT,
      CoursesSortEnum.usersCountDesc,
    );
  };

  const onSearchChange = (v) => {
    setSearchInputText(v);
  };

  const onSearchInProgress = (v) => setSearchInProgress(v);

  const value = useMemo(
    () => ({
      course,
      courses,
      coursesPagination,
      newCourses,
      userCourses,
      userCoursesPagination,
      freeCourses,
      freeCoursesPagination,
      video,
      videos,
      isLoadingVideos,
      isLoadingCourses,
      isLoadingNewCourses,
      isLoadingUserCourses,
      isLoadingFreeCourses,
      searchInputText,
      resetState,
      searchInProgress,
      loadCourses,
      loadUserCourses,
      setSearchInputText,
      setSearchResultsLength,
      setCoursesPagination,
      loadFreeCourses,
      loadCourseById,
      loadMoreVideos,
      loadVideoById,
      subscribeToCourse,
      isSingleVideoLoading,
      loadCurrentPageCourses,
      onSearchChange,
      onSearchInProgress,
      searchResultsLength,
    }),
    [
      course,
      courses,
      coursesPagination,
      resetState,
      newCourses,
      userCourses,
      userCoursesPagination,
      freeCourses,
      freeCoursesPagination,
      setSearchInputText,
      setSearchResultsLength,
      setCoursesPagination,
      video,
      searchInputText,
      searchInProgress,
      videos,
      isLoadingVideos,
      isLoadingCourses,
      isLoadingNewCourses,
      isLoadingUserCourses,
      isLoadingFreeCourses,
      loadCurrentPageCourses,
      onSearchChange,
      onSearchInProgress,
      searchResultsLength,
    ],
  );

  return (
    <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>
  );
}

CoursesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
