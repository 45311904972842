import { createConfig } from '@citmed/authv2';

export const getBaseNameFixed = () =>
  (ENV?.PUBLIC_PATH ? ENV.PUBLIC_PATH : '/')?.toString()
    .replace(/^\/*|\/*$/g, '');

const appBaseUrl = `${window.origin}${
  getBaseNameFixed() ? `/${getBaseNameFixed()}` : ''
}`;

export const getAuthConfig = () => createConfig({
  defaultRedirectPage: appBaseUrl,
  openAccess: false,
  baseApiUrl: ENV?.AUTH_URL,
  clientId: ENV?.CLIENT_ID,
  callbackPageUrl: `${appBaseUrl}/callback`
});
