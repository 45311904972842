import { memo } from 'react';
import { useCallbackPage } from '@citmed/authv2';

const CallBackPage = memo(() => {
  useCallbackPage();
  return (
    <div>
      <h3>Переадресация...</h3>
    </div>
  );
});

export default CallBackPage;
