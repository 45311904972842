import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import { useAuthorization } from '@citmed/authv2';
import styles from './App.module.scss';
import { joinClasses } from './utils/helper';
import useTheme from './hook/useTheme';
import { getRoute as getStreamRoute } from './containers/StreamPage/StreamPage.Route';
import HomePage from './containers/HomePage/HomePage';
import ProfilePage from './containers/ProfilePage/ProfilePage';
import CoursePage from './containers/CoursePage/CoursePage';
import MyCoursesPage from './containers/MyCoursesPage/MyCoursesPage';
import VideoPage from './containers/VideoPage/VideoPage';
import QuestionsPage from './containers/QuestionsPage/QuestionsPage';

import { getRoute as getHomeRoute } from './containers/HomePage/HomePage.Route';
import { getRoutes as getProfileRoutes } from './containers/ProfilePage/ProfilePage.Route';
import { getRoutes as getCourseRoutes } from './containers/CoursePage/CoursePage.Route';
import { getRoute as getMyCoursesRoute } from './containers/MyCoursesPage/MyCoursesPage.Route';
import { getRoute as getFreeCoursesRoute } from './containers/FreePage/FreePage.Route';
import { getRoutes as getVideoRoutes } from './containers/VideoPage/VideoPage.Route';
import { getRoute as getQuestionsRoute } from './containers/QuestionsPage/QuestionsPage.Route';
import { getRoute as getTermsRoute } from './containers/TermsPage/TermsPage.Route';
import { getRoute as getPrivacyRoute } from './containers/PrivacyPage/PrivacyPage.Route';
import { getRoute as getCallbackRoute } from './containers/CallbackPage/CallbackPage.Route';

import DefaultTemplate from './templates/DefaultTemplate/DefaultTemplate';
import BlankTemplate from './templates/BlankTemplate/BlankTemlate';
import FreePage from './containers/FreePage/FreePage';
import ErrorHttpPage from './containers/ErrorHttpPage/ErrorHttpPage';
import TermsPage from './containers/TermsPage/TermsPage';
import PrivacyPage from './containers/PrivacyPage/PrivacyPage';
import CallBackPage from './containers/CallbackPage/CallbackPage';

import useAuth from './hook/useAuth';
import StreamPage from './containers/StreamPage/StreamPage';

/**
 * Courses.userspace application
 * @component
 */
function App() {
  const { theme } = useTheme();

  const { setIsAccessAllowed } = useAuth();

  const { isAlive } = useAuthorization();

  useEffect(() => {
    setIsAccessAllowed(isAlive);
  }, [isAlive]);

  return (
    <div className={joinClasses([styles.coursesUserspaceApp, theme])}>
      <SkeletonTheme
        baseColor="var(--skeleton-base-color)"
        highlightColor="var(--skeleton-highlight-color)"
      >
        <Routes>
          <Route
            path={getStreamRoute()}
            element={
              <DefaultTemplate title="Трансляция">
                <StreamPage />
              </DefaultTemplate>
            }
          />
          <Route
            path={getHomeRoute()}
            element={
              <DefaultTemplate title="Главная">
                <HomePage />
              </DefaultTemplate>
            }
          />
          <Route
            path={getMyCoursesRoute()}
            element={
              <DefaultTemplate title="Мои курсы">
                <MyCoursesPage />
              </DefaultTemplate>
            }
          />
          <Route
            path={getFreeCoursesRoute()}
            element={
              <DefaultTemplate title="Бесплатные курсы">
                <FreePage />
              </DefaultTemplate>
            }
          />
          <Route path={getCallbackRoute()} element={<CallBackPage />} />
          {getProfileRoutes()
            .map((path, idx) => (
              <Route
                key={idx} // eslint-disable-line react/no-array-index-key
                path={path}
                element={
                  <DefaultTemplate title="Профиль">
                    <ProfilePage />
                  </DefaultTemplate>
                }
              />
            ))}
          {getCourseRoutes()
            .map((path, idx) => (
              <Route
                key={idx} // eslint-disable-line react/no-array-index-key
                path={path}
                element={
                  <DefaultTemplate title="Курс">
                    <CoursePage />
                  </DefaultTemplate>
                }
              />
            ))}
          {getVideoRoutes()
            .map((path, idx) => (
              <Route
                key={idx} // eslint-disable-line react/no-array-index-key
                path={path}
                element={
                  <DefaultTemplate title="Видео">
                    <VideoPage />
                  </DefaultTemplate>
                }
              />
            ))}
          <Route
            path={getQuestionsRoute()}
            element={
              <DefaultTemplate title="Вопрос администратору">
                <QuestionsPage />
              </DefaultTemplate>
            }
          />
          <Route
            path={getTermsRoute()}
            element={
              <BlankTemplate title="Пользовательское соглашение">
                <TermsPage />
              </BlankTemplate>
            }
          />
          <Route
            path={getPrivacyRoute()}
            element={
              <BlankTemplate title="Политика персональных данных">
                <PrivacyPage />
              </BlankTemplate>
            }
          />
          <Route
            path="*"
            element={
              <DefaultTemplate title="404">
                <ErrorHttpPage code="404" headerFix />
              </DefaultTemplate>
            }
          />
        </Routes>
      </SkeletonTheme>
    </div>
  );
}

App.propTypes = {};

export default App;
