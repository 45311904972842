import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './MyCoursesPage.module.scss';

import useAuth from '../../hook/useAuth';
import useCourses from '../../hook/useCourses';
import { CoursesSortEnum } from '../../utils/helper';
import { COURSES_MYCOURSES_ELEMENTS_COUNT } from '../../utils/settings';

import CoursesPageContent from '../../layouts/CoursesPageContent/CoursesPageContent';
import { getRoute as buildHomeRoute } from '../HomePage/HomePage.Route';

/**
 * My courses page
 * @component
 */
function MyCoursesPage() {
  const { user } = useAuth();
  const {
    userCourses,
    userCoursesPagination,
    loadUserCourses,
    isLoadingUserCourses,
    searchInputText,
  } = useCourses();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user && userCourses?.length === 0) {
      // eslint-disable-next-line max-len
      loadUserCourses(
        1,
        undefined,
        COURSES_MYCOURSES_ELEMENTS_COUNT,
        CoursesSortEnum.purchasedAtDesc,
      );
    }
  }, [user]);

  const handleNoCoursesButton = () => {
    navigate(buildHomeRoute());
  };

  const loadMore = () => {
    if (user && userCoursesPagination) {
      // console.log('loadMore', coursesPagination);
      const nextPage = userCoursesPagination.page + 1;
      if (nextPage <= userCoursesPagination.totalPages) {
        // console.log('load page:', nextPage);
        loadUserCourses(
          userCoursesPagination.page + 1,
          searchInputText,
          COURSES_MYCOURSES_ELEMENTS_COUNT,
          CoursesSortEnum.purchasedAtDesc,
        );
      }
    }
  };

  return (
    <div className={styles.holder}>
      <CoursesPageContent
        title="Мои курсы"
        courses={userCourses}
        isLoading={isLoadingUserCourses}
        onLazyLoad={loadMore}
        nocourses={{
          text: 'Вы не приобрели ни одного курса или приобретенный Вами курс еще не стартовал',
          btn: 'Смотреть все курсы',
          onButton: handleNoCoursesButton,
        }}
        hideNewOnPhone
      />
    </div>
  );
}

MyCoursesPage.propTypes = {};

export default MyCoursesPage;
