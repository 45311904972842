/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
import {
  createContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { useLogout } from '@citmed/authv2';
import { getJwtPayload } from '../utils/helper';

export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const logoutLib = useLogout();
  const [user, setUser] = useState(null);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);

  useEffect(() => {
    if (isAccessAllowed) {
      loadUser(); // eslint-disable-line no-use-before-define
    }
  }, [isAccessAllowed]);

  /**
   * Get access token of the currently logged in user
   * @returns Promise with access token or error
   */
  const getAccess = async () => {
    const at = localStorage.getItem('at');
    if (at) {
      return Promise.resolve(at);
    }
    return Promise.reject(new Error('Access token not found'));
  };

  const setUserNull = () => {
    setUser(null);
  };

  /**
   * Load logged in user
   */
  const loadUser = async () => {
    const token = localStorage.getItem('at');
    const userFromToken = getJwtPayload(token);

    if (userFromToken) {
      setUser(userFromToken);
      return userFromToken;
    }
    setUserNull();
  };

  /**
   * Serverless logout - just remove tokens
   */
  const logout = async () => {
    await logoutLib();
    setUser(null);
    // Remove saved location on forced logout
  };

  const value = useMemo(
    () => ({
      user,
      setUserNull,
      logout,
      setIsAccessAllowed,
      loadUser,
      getAccess,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
