import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './FreePage.module.scss';

import useAuth from '../../hook/useAuth';
import useCourses from '../../hook/useCourses';
import { CoursesSortEnum } from '../../utils/helper';
import { COURSES_FREE_ELEMENTS_COUNT } from '../../utils/settings';

import CoursesPageContent from '../../layouts/CoursesPageContent/CoursesPageContent';
import { getRoute as buildHomeRoute } from '../HomePage/HomePage.Route';

/**
 * Free courses page
 * @component
 */
function FreePage() {
  const { user } = useAuth();
  const {
    freeCourses,
    freeCoursesPagination,
    loadFreeCourses,
    isLoadingFreeCourses,
    searchInputText,
  } = useCourses();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user && freeCourses?.length === 0) {
      loadFreeCourses(
        1,
        undefined,
        COURSES_FREE_ELEMENTS_COUNT,
        CoursesSortEnum.usersCountDesc,
      );
    }
  }, [user]);

  const handleNoCoursesButton = () => {
    navigate(buildHomeRoute());
  };

  const loadMore = () => {
    if (user && freeCoursesPagination) {
      // console.log('loadMore', coursesPagination);
      const nextPage = freeCoursesPagination.page + 1;
      if (nextPage <= freeCoursesPagination.totalPages) {
        // console.log('load page:', nextPage);
        loadFreeCourses(
          freeCoursesPagination.page + 1,
          searchInputText,
          COURSES_FREE_ELEMENTS_COUNT,
          CoursesSortEnum.usersCountDesc,
        );
      }
    }
  };

  return (
    <div className={styles.holder}>
      <CoursesPageContent
        title="Бесплатные курсы"
        courses={freeCourses}
        isLoading={isLoadingFreeCourses}
        onLazyLoad={loadMore}
        nocourses={{
          text: 'На данный момент бесплатные курсы отсутствуют, мы уведомим Вас о бесплатных новинках',
          btn: 'Смотреть все курсы',
          onButton: handleNoCoursesButton,
        }}
      />
    </div>
  );
}

FreePage.propTypes = {};

export default FreePage;
