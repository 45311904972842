import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import styles from '../StreamPage.module.scss';
import { StreamShape } from '../../../apis/models/stream';

export function VideoSection({ stream, isStreamLoading }) {
  return (
    <div className={styles.infoSection}>
      {isStreamLoading ? (
        <Skeleton className={styles.streamSkeleton} />
      ) : stream?.iframe ? (
        <iframe
          title={stream?.title}
          src={stream?.iframe}
          width="100%"
          allow="fullscreen"
          className={styles.video}
          allowFullScreen
        />
      ) : (
        <img
          src={stream?.cover?.instances[0]?.url}
          alt={stream?.title}
          className={styles.streamPreview}
        />
      )}
      <span className={styles.secondTitle}>
        {isStreamLoading ? <Skeleton /> : stream?.title}
      </span>
      <div className={styles.courseDescription}>
        <div>{isStreamLoading ? <Skeleton /> : stream?.shortDescription}</div>
      </div>
    </div>
  );
}

VideoSection.propTypes = {
  stream: StreamShape.isRequired,
  isStreamLoading: PropTypes.bool.isRequired,
};

VideoSection.defaultProps = {};
