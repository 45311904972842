import React, { useEffect } from 'react';

import styles from './HomePage.module.scss';

import useAuth from '../../hook/useAuth';
import useCourses from '../../hook/useCourses';
import { CoursesSortEnum } from '../../utils/helper';
import { COURSES_HOME_ELEMENTS_COUNT } from '../../utils/settings';

import CoursesPageContent from '../../layouts/CoursesPageContent/CoursesPageContent';

/**
 * Home page
 * @component
 */
function HomePage() {
  const { user } = useAuth();
  const {
    courses,
    coursesPagination,
    loadCourses,
    isLoadingCourses,
    searchInputText,
  } = useCourses();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user && courses.length === 0) {
      loadCourses(
        1,
        undefined,
        COURSES_HOME_ELEMENTS_COUNT,
        CoursesSortEnum.usersCountDesc,
      );
    }
  }, [user]);

  const loadMore = () => {
    if (user && coursesPagination) {
      // console.log('loadMore', coursesPagination);
      const nextPage = coursesPagination.page + 1;
      if (nextPage <= coursesPagination.totalPages) {
        // console.log('load page:', nextPage);
        loadCourses(
          coursesPagination.page + 1,
          searchInputText,
          COURSES_HOME_ELEMENTS_COUNT,
          CoursesSortEnum.usersCountDesc,
        );
      }
    }
  };

  return (
    <div className={styles.holder}>
      <CoursesPageContent
        title="Все курсы"
        courses={courses}
        isLoading={isLoadingCourses}
        onLazyLoad={loadMore}
      />
    </div>
  );
}

HomePage.propTypes = {};

export default HomePage;
