import React, { useCallback, useState } from 'react';

import styles from './VideoLine.module.scss';

import useVideoControls from '../../../../hook/useVideoControls';
import { prepareTimeFromSeconds } from '../../../../utils/strings';
// import useOutMouseUp from '../../../../hook/useOutMouseUp';

function VideoLine() {
  const [hoverPos, setHoverPos] = useState(0);
  const {
    playedSec,
    loadedSec,
    durationSec,
    setTargetSec,
  } = useVideoControls();

  // TODO: Create dragging scroll while mouse outside component
  // const ref = useOutMouseUp(() => {
  //   // console.log('OutMouseUp');
  // });

  const getPercentFromPosition = useCallback((e) => {
    const { width, left } = e.target.offsetParent.getBoundingClientRect();
    const result = (e.clientX - left) / width;
    return result > 0 ? result : 0;
  });

  const getTimeFromClick = (e) => {
    const percent = getPercentFromPosition(e);
    return Math.round(percent * durationSec);
  };

  const handleClick = (e) => {
    const time = getTimeFromClick(e);
    // console.log('handleProgressChange', time);
    setTargetSec(time);
  };

  // TODO: Create dragging scroll while mouse outside component
  // const handleMouseDown = (e) => {
  //    console.log('mousedown');
  // };
  // const handleMouseUp = (e) => {
  //   console.log('mouseup');
  // }

  const handleMouseMove = (e) => {
    const percent = getPercentFromPosition(e);
    if (hoverPos !== percent) {
      setHoverPos(percent);
    }
  };

  const handleMouseBlur = () => {
    // console.log('handleMouseBlur');
    setHoverPos(0);
  };

  return (
    <div
      className={styles.videoLine}
      // onClick={handleClick}
      // onMouseMove={handleMouseMove}
      draggable={false}
    >
      <div className={styles.videoLineRounder}>
        <div
          className={styles.loadedBar}
          style={{ width: `${(loadedSec / durationSec) * 100}%` }}
        />
        <div
          className={styles.playedBar}
          style={{ width: `${(playedSec / durationSec) * 100}%` }}
        />
        <div
          className={styles.targetBar}
          style={{ width: `${(hoverPos * 100)}%` }}
        />
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          // ref={ref}
          className={styles.videoLineOverlay}
          onClick={handleClick}
          // onMouseDown={handleMouseDown}
          // onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onBlur={handleMouseBlur}
        />
      </div>
      <div
        className={styles.targetTime}
        style={{ left: `${hoverPos * 100}%` }}
      >
        {prepareTimeFromSeconds(hoverPos * durationSec)}
      </div>
    </div>
  );
}
VideoLine.propTypes = {
};

export default VideoLine;
