/* eslint-disable no-underscore-dangle */
import { api } from './configs/axiosConfig';
import {
  defineCancelAPIObject,
  APIError,
  showAPIError,
} from './configs/axiosUtils';
import { isResponse2xx } from '../utils/responses';

export const coursesAPI = {
  // Define the cancel API object
  _cancelApiObject: function _cancelApiObject() {
    defineCancelAPIObject(this);
  },

  // Prepare cancellation signal for the method with specified name
  _getCancellationSignal: function getSignal(name, cancel) {
    if (cancel) {
      return this._cancelApiObject[name].handleRequestCancellation().signal;
    }
    return undefined;
  },

  /**
   * Get list of courses
   * @param {Boolean} isFree Flag if courses should be free
   * @param {Boolean} isPurchased Flag if courses should be purchased by selected user
   * @param {Number} page Number of the page
   * @param {String} search Search keyword
   * @param {Number} size Elements count on the page
   * @param {String} sorts Sort list by field name with + or - at the end for select sort direction
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Courses data or null
   */
  getCourses: async function getCourses(
    isFree,
    isPurchased,
    page,
    search,
    size,
    sorts,
    cancel = false,
  ) {
    const url = '/v2/user/courses';
    const params = {
      isFree,
      isPurchased,
      page,
      search,
      size,
      sorts,
    };

    try {
      const response = await api.request({
        url,
        method: 'GET',
        params,
        signal: this._getCancellationSignal(this.getCourses.name, cancel),
      });

      if (response?.status === 200) {
        return response.data;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },

  /**
   * Subscribe current user to the course
   * @param {String} courseId Course ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response or null in case of error
   */
  subscribeToCourse: async function subscribeToCourse(
    courseId,
    cancel = false,
  ) {
    const url = `/v2/user/courses/${courseId}/subscribe`;

    try {
      const response = await api.request({
        url,
        method: 'PUT',
        signal: this._getCancellationSignal(
          this.subscribeToCourse.name,
          cancel,
        ),
      });

      if (isResponse2xx(response.status)) {
        return response;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },

  /**
   * Get specified course by ID
   * @param {String} courseID Сourse ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response with course data or null
   */
  getCourseById: async function getCourseById(courseId, cancel = false) {
    const url = `v2/user/courses/${courseId}`;

    try {
      const response = await api.request({
        url,
        method: 'GET',
        signal: this._getCancellationSignal(this.getCourseById.name, cancel),
      });

      if (response?.status === 200) {
        return response;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },

  /**
   * Get videos of course specified by ID
   * @param {String} courseId Сourse ID
   * @param {Number} page Offset from the beginning of the selection
   * @param {String} search Search criteria (filter)
   * @param {Number} size Size of one page in items
   * @param {String} sorts Parameter name for sorting
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Videos data or null
   */
  getCourseVideos: async function getCourseVideos(
    courseId,
    page,
    search,
    size,
    sorts,
    cancel = false,
  ) {
    const url = `/v2/user/courses/${courseId}/videos`;
    const params = {
      page,
      search,
      size,
      sorts,
    };

    try {
      const response = await api.request({
        url,
        method: 'GET',
        params,
        signal: this._getCancellationSignal(this.getCourseVideos.name, cancel),
      });

      if (response?.status === 200) {
        return response;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },

  /**
   * Get image for specified object
   * @param {String} objectId Object ID (now can be Course or Video ID)
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Image data or null
   */
  getImageByID: async function getCourseImage(objectId, cancel = false) {
    const url = `/pictures/${objectId}`;

    try {
      const response = await api.request({
        url,
        method: 'GET',
        signal: this._getCancellationSignal(this.getImageByID.name, cancel),
      });

      if (response?.status === 200) {
        return response.data;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },

  /**
   * Get video with specified ID from parent course with specified ID
   * @param {String} videoId Video ID
   * @param {String} videoId Course ID
   * @param {Boolean} cancel Flag that says whether the previous request should be canceled
   * @returns Response with video data or null
   */
  getVideoById: async function getVideoById(videoId, courseId, cancel = false) {
    const url = `/v2/user/courses/${courseId}/videos/${videoId}`;

    try {
      const response = await api.request({
        url,
        method: 'GET',
        signal: this._getCancellationSignal(this.getVideoById.name, cancel),
      });

      if (response?.status === 200) {
        return response;
      }

      showAPIError(url, response);
      return null;
    } catch (error) {
      showAPIError(url, new APIError(error));
      return null;
    }
  },
};

export default coursesAPI;
