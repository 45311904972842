import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { getAuthConfig } from './getAuthConfig';
import { requestInterceptorUse } from '@citmed/authv2';

export const api = axios.create({
  // withCredentials: true,
  baseURL: ENV.API_URL
});

// Registering the custom error handler to the "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  const statusCode = error.response?.status;

  // Logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
});

api.interceptors.request.use(requestInterceptorUse({
  authNavigationService: getAuthConfig()
    .getAuthNavigationService(),
  sessionService: getAuthConfig()
    .getCurrentSessionService(),
  tokenService: getAuthConfig()
    .getTokenService(),
  openAccess: getAuthConfig()
    .getIsOpenAccess()
}));

export default api;
